@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';

$tablet: '768px';
$desktop: '1024px';
$xl: '1400px';

.ResponsivePreview {
    display: flex;
    flex-direction: column;
    height: 100%;

    &_header {
        position: relative;
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 3rem;

        @include screen-greater-than(1024px) {
            width: 1060px;
        }
    }

    &_content {
        position: relative;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding-bottom: 2rem;

        @include screen-greater-than(1024px) {
            margin-top: 4rem;
        }
    }
}

.Breakpoints {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 8.75rem;
    height: 2.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    background-color: $base10;
    border-radius: 0.875rem;
    border-radius: 1px solid $base20;
}

.Breakpoint {
    background-color: transparent;
    border-width: 0;
    border-radius: 0.25rem;
    cursor: pointer;

    &_icon {
        color: $base80;

        &___active {
            color: $primary50;
        }
    }

    &_label {
    }

    &:hover {
        background-color: $primary20;
    }

    &:focus {
        outline-color: $primary70;
    }
}

.FullScreen {
    &_icon {
        cursor: pointer;

        @include screen-smaller-than(1023px) {
            margin-left: 1.5rem;
        }

        @include screen-greater-than(1024px) {
            position: absolute;
            right: 15%;
            bottom: 0;
        }
    }
}

.Preview {
    border-color: $base20;
    transition: none;

    @include screen-greater-than($desktop) {
        transition: width 0.75s, border 0.75s, height 0.75s;
    }

    &_frame {
        width: 100%;
        height: calc(100% - 1.25rem);
    }

    &___mobile {
        width: 75vw;
        height: 55vh;
        margin-top: 2rem;
        border-top: 3rem solid $base20;
        border-right: 1rem solid $base20;
        border-bottom: 2.5rem solid $base20;
        border-left: 1rem solid $base20;
        border-radius: 1.25rem;

        @include screen-greater-than($tablet) {
            width: 40vw;
            height: 55vh;
        }

        @include screen-greater-than($desktop) {
            width: 30vw;
            height: 55vh;
        }

        @include screen-greater-than($xl) {
            width: 20vw;
            height: 55vh;
        }
    }

    &___tablet {
        position: absolute;
        top: -15%;
        width: 130vw;
        height: 80vh;
        border-top: 1rem solid $base20;
        border-right: 0.75rem solid $base20;
        border-bottom: 1rem solid $base20;
        border-left: 0.75rem solid $base20;
        border-radius: 0.85rem;
        transform: scale(0.6);

        @include screen-greater-than($tablet) {
            top: -35%;
            width: 110vw;
            height: 110vh;
            border: 2.5rem solid $base20;
            border-bottom: 4rem solid $base20;
            border-radius: 0.85rem;
            transform: scale(0.5);
        }

        @include screen-greater-than($desktop) {
            position: unset;
            width: 60vw;
            height: 60vh;
            border-top: 2.5rem solid $base20;
            border-right: 5rem solid $base20;
            border-bottom: 2.5rem solid $base20;
            border-left: 5rem solid $base20;
            border-radius: 1.25rem;
            transform: scale(1);
        }
    }

    &___desktop {
        position: absolute;
        top: -60%;
        width: 300vw;
        height: 125vh;
        border: 0 solid $base20;
        border-top: 1rem solid $base20;
        border-right: 1rem solid $base20;
        border-bottom: 3.5rem solid $base20;
        border-left: 1rem solid $base20;
        border-radius: 1rem;
        transform: scale(0.3);

        @include screen-greater-than($tablet) {
            top: -25%;
            width: 200vw;
            height: 100vh;
            transform: scale(0.4);
        }

        @include screen-greater-than($desktop) {
            position: unset;
            width: 85vw;
            height: 60vh;
            transform: scale(1);
        }

        @include screen-greater-than($xl) {
            width: 70vw;
            height: 60vh;
            transform: scale(1);
        }
    }
}

.BrowserBar {
    display: flex;
    align-items: center;
    height: 1.25rem;
    padding-left: 1rem;
    background-color: $base10;

    @include screen-greater-than(768px) {
        height: 1.75rem;
    }

    &_dot {
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 0.75rem;
        background-color: $base20;
        border-radius: 50%;

        @include screen-greater-than(768px) {
            width: 0.85rem;
            height: 0.85rem;
        }
    }
}
