.NewDomainPageContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
}

.Toast {
    min-height: 10rem;
    margin: 2rem auto 0;

    &_content {
        position: relative !important; // Needed to fix css specificity issue
        left: -1rem !important; // Needed to fix css specificity issue
    }
}
