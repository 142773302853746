// stylelint-disable-next-line plugin/selector-bem-pattern
.fade-enter {
    opacity: 0;
}

// stylelint-disable-next-line plugin/selector-bem-pattern
.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

// stylelint-disable-next-line plugin/selector-bem-pattern
.fade-exit {
    display: none;
}
