.DomainForm {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    &_inputSection {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &_textInput {
        flex: 1;
    }
}
