@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.DomainSelectionPage {
    &_section {
        height: calc(100% - 6rem);
        padding: 4.875rem 0.75rem 0;

        @include screen-smaller-than($desktop) {
            padding-top: 1.5rem;
        }
    }

    &_options {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 3.5rem;
    }

    &_footer {
        position: sticky;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        width: 50vw;
        margin: 2.5rem 0 0 -3rem;
        padding: 2rem 2rem;
        background-color: $white;
        box-shadow: 0 -4px 8px 0 #353f5e1a;

        @include screen-smaller-than($desktop) {
            width: 100vw;
            margin: 2.5rem 0 -4rem -0.75rem;
        }

        @include screen-smaller-than($tablet) {
            flex-direction: column;
            padding: 2rem 1rem;
        }
    }
}

.DomainSelectionPageContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
}

.SelectionOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_label {
        margin: 0.5rem 0 0;
        padding: 0 2.5rem 0;
        color: $base70;
        text-align: center;
        @include label;
    }
}

.Button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1rem;

    @include screen-smaller-than($tablet) {
        width: 100%;
        margin: 0.5rem 0;
    }
}

.Illustration {
    position: relative;
    width: 35rem;
    height: 35rem;
    margin: 0 auto 0;
    padding-top: 3rem;

    @include screen-smaller-than($tablet) {
        width: 100%;
    }
}
