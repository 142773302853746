@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';
@import 'spoton-lib/styles/text';

$tablet: get-breakpoint('tablet');

.PaletteTileWrapper {
    position: relative;
    padding: 0;
    background-color: transparent;
    border: 3px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;

    &_checkIcon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 2rem;
        height: 2rem;
    }

    &_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary30;
        border-radius: 0.25rem;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.5;
        }
    }

    &___active {
        border: 3px solid $primary50;
    }

    &:hover {
        border: 3px solid $primary50;
    }

    &:focus-visible {
        outline-color: $primary50;
        outline-offset: 0.25rem;
    }
}

.PaletteTile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 10.5rem;
    height: 5rem;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #08031d;
    border-radius: 0.25rem;
    box-shadow: 0 6px 12px #353f5e1a;

    @include screen-smaller-than($tablet) {
        width: 100%;
    }

    &_colors {
        display: flex;
        flex-basis: 1.625rem;
        height: 100%;
    }

    &_theme {
        display: flex;
        flex: 1;
        height: 100%;
    }
}

.PaletteTileColors {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    &_item {
        display: block;
        height: 100%;
        list-style: none;
    }
}

.PaletteTileTheme {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
    padding: 0.5rem;

    &_name {
        width: 100%;
        margin: 0;
        text-align: start;
        @include h4;

        @include screen-smaller-than($tablet) {
            font-size: 1.875rem;
            text-align: right;
        }
    }
}
