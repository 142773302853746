/* stylelint-disable plugin/no-unsupported-browser-features, scss/comment-no-loud */
@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');

.StepperIllustration {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    &_message {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;

        @include screen-smaller-than($desktop) {
            display: none;
        }
    }
}

.Icon {
    min-width: 2.75rem;
    margin-right: 0.875rem;
}

.StepperDescription {
    &_title {
        margin-bottom: 0.5rem !important;
        @include h6;
    }

    &_text {
        @include p;
    }
}
