// stylelint-disable max-nesting-depth
@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/color-variables';

.RadioInput {
    position: relative;
    margin: 0.5rem 0;

    &_input {
        width: 0.75rem;
        height: 0.75rem;
        margin: 0 0 0 0.25rem;

        &::before {
            position: absolute;
            top: 1px;
            left: 0.125rem;
            width: 1rem;
            height: 1rem;
            background: white;
            border-radius: 50%;
            content: '';
        }

        &::after {
            position: absolute;
            top: 1px;
            left: 0.125rem;
            display: inline-block;
            width: 0.875rem;
            height: 0.875rem;
            border: 1px solid $black;
            border-radius: 50%;
            content: '';
        }

        &:checked {
            &::after {
                background: $primary50;
                border: 1px solid $primary50;
            }
        }

        &:hover {
            &::before {
                background: $primary10;
                transform: scale(1.8);
            }
        }

        &:active {
            &::before {
                background: $primary20;
                transform: scale(1.8);
            }
        }

        &:focus-visible {
            outline: none;

            &::before {
                top: 0;
                left: 1px;
                border: 1px solid $primary70;
                transform: scale(1.4);
            }
        }
    }

    &_label {
        position: relative;
        display: inline-block !important;
        margin-left: 0.75rem !important;
        cursor: pointer;
        @include p;
    }

    &_checkIcon {
        position: absolute;
        top: 2px;
        left: 0.2rem;
        width: 0.875rem;
        height: 0.875rem;
    }
}
