@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/color-variables';

.CategoryTile {
    margin-bottom: 1rem;
    padding: 0;
    list-style-type: none;
    background-color: $white;
    border: 3px solid transparent;
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px rgba(53, 63, 94, 0.1);

    &_icon {
        margin-right: 1.25rem;
    }

    &_title {
        @include h6;
    }

    &_header {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem 1.5rem;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:focus {
            outline-color: $primary50;
            outline-offset: -0.5rem;
        }
    }

    &_checkIcon {
        width: 2rem;
        height: 2rem;
        margin-left: auto;
    }

    &___active {
        border-color: $primary50;
    }

    &:not(&___expanded) {
        &:hover {
            background-color: $primary10;
            border-color: $primary50;
        }
    }
}

.SubCategory {
    &_list {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        margin-left: 5rem;
        padding: 0;
    }

    &_item {
        color: $black;
        list-style-type: none;
    }

    &_radio {
        color: $black;
    }
}
