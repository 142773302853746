.App {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &_navbar {
    }

    &_content {
        flex-grow: 1;
    }
}
