@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';

$tablet: get-breakpoint('tablet');

.Navbar {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $base20;

    @include screen-smaller-than($tablet) {
        flex-direction: column;
        padding: 0;
        border-bottom: 0;
    }

    &_left,
    &_center,
    &_right {
        flex: 1;
        @include screen-smaller-than($tablet) {
            width: 100%;
        }
    }

    &_left {
        @include screen-smaller-than($tablet) {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.5rem;
            padding: 1.25rem 0;
            border-bottom: 1px solid $base20;
        }
    }

    &_right {
        text-align: right;
    }
}
