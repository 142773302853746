@import 'spoton-lib/styles/color';

.BusinessLocation {
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;
    box-shadow: 0 4px 4px 0 #00000040;

    &_map {
        width: 100%;
        height: 100%;
        border-radius: 0.625rem;
    }
}
