@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.Container {
    display: flex;
    flex-direction: column;
    gap: 2.875rem;
    margin: 0 auto;
}

.Content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    background-color: $white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(53, 63, 94, 0.1);

    &_title {
        @include h5;
    }

    &_description {
        white-space: pre-line;
        @include p;
    }
}

.Disclaimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    column-gap: 1rem;

    &_content {
        @include p;
    }

    &_svg {
        align-self: center;
        width: 3rem;
        height: auto;
    }
}
