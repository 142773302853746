@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/color-variables';

$white85: rgba(255, 255, 255, 0.85);

.ImagePicker {
    position: relative;
    box-sizing: border-box;
    min-height: 8.625rem;
    max-height: 8.625rem;
    padding: 0;
    overflow: hidden;
    border: 2px solid $primary30;
    border-radius: 0.25rem;
    cursor: pointer;

    &_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &_checkIcon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 2rem;
        height: 2rem;
    }

    &___active {
        border: 3px solid $primary50;
    }

    &_highlight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary30;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.5;
        }
    }

    &:focus-visible {
        outline-color: $primary50;
        outline-offset: 0.25rem;
    }
}

.Label {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    background-color: $white85;
    border-radius: 0.125rem;

    &_text {
        color: $primary50 !important;
        @include label1;
    }
}
