@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

// stylelint-disable plugin/no-unsupported-browser-features
.BusinessDetailForm {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    &_form {
        flex: 1;
    }

    &_actions {
        position: sticky;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        width: 50vw;
        margin: 2.5rem 0 0 -3rem;
        padding: 2rem 2rem;
        background-color: $white;
        box-shadow: 0 -4px 8px 0 #353f5e1a;

        @include screen-smaller-than($desktop) {
            width: 100vw;
            margin: 2.5rem 0 -4rem -0.75rem;
        }

        @include screen-smaller-than($tablet) {
            flex-direction: column;
            padding: 2rem 1rem;
        }
    }
}

.Button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1rem;

    @include screen-smaller-than($tablet) {
        width: 100%;
        margin: 0.5rem 0;
    }
}
