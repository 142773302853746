@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$tablet: '768px';
$desktop: '1024px';
$xl: '1400px';

.SiteStylingPage {
    &_mainSection {
        height: calc(100% - 6rem);
        padding: 4.875rem 0.75rem 0;

        @include screen-smaller-than($desktop) {
            padding-top: 1.5rem;
        }
    }

    &_footer {
        position: sticky;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        width: 50vw;
        margin: 2.5rem 0 -4rem -3rem;
        padding: 2rem 2rem;
        background-color: $white;
        box-shadow: 0 -4px 8px 0 #353f5e1a;
        @include screen-smaller-than($desktop) {
            width: 100vw;
            margin: 2.5rem 0 -4rem -0.75rem;
        }
        @include screen-smaller-than($tablet) {
            flex-direction: column;
            padding: 2rem 1rem;
        }
    }

    &_paletteSection {
        height: calc(100% - 6rem);
        padding: 2rem 0.75rem;

        @include screen-smaller-than($desktop) {
            padding-top: 1.5rem;
        }
    }
}

.Palettes {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;

    &_list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;

        @include screen-smaller-than($tablet) {
            flex-direction: column;
        }
    }
}

.Preview {
    display: inherit;
    height: 80vh;
    margin: 2.5rem 0;
    border-color: $base20;
    transition: none;

    @include screen-smaller-than($desktop) {
        display: none;
    }

    @include screen-greater-than($desktop) {
        transition: width 0.75s, border 0.75s, height 0.75s;
    }

    &_frame {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 1.25rem);
        background-color: $base10;
        border: 1px solid $base20;
    }

    &___desktop {
        position: absolute;
        top: -5%;
        width: 100vw;
        height: 100vh;
        border: 0 solid $base20;
        border-top: 1rem solid $base20;
        border-right: 1rem solid $base20;
        border-bottom: 3.5rem solid $base20;
        border-left: 1rem solid $base20;
        border-radius: 1rem;
        transform: scale(0.4);

        @include screen-greater-than($desktop) {
            top: -16%;
            right: -25%;
            width: 100vw;
            height: 125vh;
            transform: scale(0.4);
        }

        @include screen-greater-than($xl) {
            top: -16%;
            right: -16%;
            width: 80vw;
            height: 125vh;
            transform: scale(0.5);
        }
    }
}

.BrowserBar {
    display: flex;
    align-items: center;
    height: 1.25rem;
    padding-left: 1rem;
    background-color: $base20;
    border-radius: 5px 5px 0 0;

    @include screen-greater-than(768px) {
        height: 1.75rem;
    }

    &_dot {
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 0.75rem;
        background-color: $base10;
        border-radius: 50%;

        @include screen-greater-than(768px) {
            width: 0.85rem;
            height: 0.85rem;
        }
    }
}

.Button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1rem;

    @include screen-smaller-than($tablet) {
        width: 100%;
        margin: 0.5rem 0;
    }
}
