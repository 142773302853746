/* stylelint-disable plugin/no-unsupported-browser-features, scss/comment-no-loud */
@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$background: #eff5ff;

.SplitScreen {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: calc(100vh - 5.5rem);

    &_section {
        flex: 1;
        padding: 0 2.25rem 0;
        overflow-x: hidden;
        overflow-y: auto;

        &___right {
            background-color: $background;
            // stylelint-disable max-nesting-depth
            @include screen-smaller-than($desktop) {
                background-color: transparent;
            }
            // stylelint-enable max-nesting-depth
        }
    }
}
