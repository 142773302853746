@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/text';

.FileLoader {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 24.375rem;
    max-height: 12.25rem;
    margin: 0 auto;
    color: $base90;
    text-align: center;
    background-color: $white;
    border: 0;

    &_filled {
        border-color: $base30;
        border-style: dashed;
        border-width: 2px;
        border-radius: 1rem;
    }

    &_input {
        display: none;
    }

    &_label {
        display: flex !important;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem 2.5rem;
    }

    &_labelSubtitle {
        margin: 0;
        @include sub1;
    }

    &_button {
        width: 100%;
        padding: 0.25rem;
        color: $base70;
        font-size: 1rem;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        @include label;

        &:hover {
            text-decoration-line: underline;
        }
    }

    &_buttonText {
        margin: 0;
    }

    &_preview {
        display: flex;
        max-width: 100%;
        overflow: hidden;
        border-color: $base30;
        border-style: dashed;
        border-width: 2px;
        border-radius: 1rem;
    }

    &_previewBtn {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        width: 1.25rem;
        height: 1.25rem;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    /* stylelint-disable csstools/use-nesting, scss/comment-no-loud  */
    &:hover &_previewBtn {
        opacity: 1;
    }

    /* stylelint-enable csstools/use-nesting, scss/comment-no-loud */
    &_previewImg {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
