@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.ImagePicker {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;

    @include screen-smaller-than($tablet) {
        margin-bottom: 2rem;
    }

    &_wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        column-gap: 1rem;
        row-gap: 2.5rem;

        @include screen-smaller-than($tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.ImagePickerPage {
    &_section {
        height: calc(100% - 6rem);
        padding: 4.875rem 0.75rem 0;

        @include screen-smaller-than($desktop) {
            padding-top: 1.5rem;
        }
    }

    &_footer {
        position: sticky;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        width: 50vw;
        margin: 2.5rem 0 -4rem -3rem;
        padding: 2rem 2rem;
        background-color: $white;
        box-shadow: 0 -4px 8px 0 #353f5e1a;

        @include screen-smaller-than($desktop) {
            width: 100vw;
            margin: 2.5rem 0 -4rem -0.75rem;
        }

        @include screen-smaller-than($tablet) {
            flex-direction: column;
            padding: 2rem 1rem;
        }
    }

    &_banner {
        position: sticky;
        top: 0;
        z-index: 1;
        margin: 0 -2.25rem;

        @include screen-smaller-than($desktop) {
            margin: 0;
        }
    }
}

.Button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1rem;

    @include screen-smaller-than($tablet) {
        width: 100%;
        margin: 0.5rem 0;
    }
}

.Illustration {
    width: 100%;
    height: 18.75rem;
    min-height: 12.5rem;
    padding: 6rem 0 4rem;

    @include screen-smaller-than($desktop) {
        box-sizing: border-box;
        padding: 1rem;
    }

    @include screen-smaller-than($tablet) {
        display: none;
    }

    &_wrapper {
        padding-bottom: 4rem;

        @include screen-smaller-than($desktop) {
            padding-bottom: 0;
        }
    }
}

.Error {
    position: absolute;
    top: 95px;
    right: 50vw;
    left: 0;
    z-index: 1;

    @include screen-smaller-than($desktop) {
        right: 0;
    }
}
