@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.FinalDemoPage {
    &_section {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 3.25rem;

        @include screen-smaller-than($desktop) {
            align-items: center;
            padding-left: unset;
        }
    }

    &_title {
        max-width: 24.5rem;
        margin-bottom: 0.625rem !important;
        @include h1;

        @include screen-smaller-than($desktop) {
            display: none !important;
            @include h4;
        }
    }

    &_description {
        margin: 1rem !important;
        text-align: center;
        @include p;

        @include screen-greater-than($desktop) {
            max-width: 22.25rem;
            margin: 0 0 1.5rem !important;
            text-align: left;
            @include sub1;
        }
    }
}

.Button {
    width: max-content;
}

.Illustration {
    width: 80%;

    @include screen-smaller-than($desktop) {
        width: 82%;
        height: auto;
    }

    &_wrapper {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        @include screen-smaller-than($desktop) {
            align-items: center;
        }
    }

    &_title {
        display: none !important;
        margin: 2.25rem 0 2.75rem 0 !important;
        @include h4;

        @include screen-smaller-than($desktop) {
            display: block !important;
        }
    }
}
