@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');

.PreviewActions {
    display: flex;

    @include screen-smaller-than($desktop) {
        justify-content: center;
    }

    &_share {
        margin-right: 1rem;
        padding: 0.75rem;
        border-radius: 8px;
    }

    &_edit {
        display: flex;
        align-items: center;
    }
}

.ShareDemoModal {
    width: 768px;
    padding: 0;

    @include screen-smaller-than($desktop) {
        width: 90%;
    }
}
