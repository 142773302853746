@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/color-variables';

.Stepper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin: 0;
    padding-left: 0;
}

.Item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;

    &_icon {
        z-index: 1;
        width: 2rem;
        height: 2rem;
    }

    &_step {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        background-color: $base30;
        border-radius: 50%;

        &___active {
            background-color: $primary50;
        }
    }

    &_number {
        color: $white !important;
        @include sub1;
    }

    &::before {
        position: relative;
        top: 1rem;
        width: 100%;
        height: 1px;
        background-color: $base20;
        content: '';
    }

    &:first-of-type {
        &::before {
            right: -30%;
            width: 60%;
        }
    }

    &:last-of-type {
        &::before {
            right: 30%;
            width: 60%;
        }
    }
}

.ItemIcon {
    width: 2rem;
    height: 2rem;
}

.Title {
    padding-top: 0.5rem;

    &_text {
        color: $base50 !important;
        text-align: center;
        @include p;

        &___active {
            color: $black !important;
        }
    }
}
