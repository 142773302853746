@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.AboutBusinessPage {
    &_section {
        height: calc(100% - 6rem);
        padding: 4.875rem 0.75rem 0;

        @include screen-smaller-than($desktop) {
            padding-top: 1.5rem;
        }
    }

    &_illustration {
        box-sizing: content-box;
        max-width: 40rem;
        height: 18.75rem;
        min-height: 12.5rem;
        margin: 0 auto;
        padding: 4.875rem 0;

        @include screen-smaller-than($desktop) {
            box-sizing: border-box;
            padding: 1rem;
        }

        @include screen-smaller-than($tablet) {
            display: none;
        }
    }
}

.AboutBusinessPageSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
