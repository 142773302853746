// stylelint-disable plugin/no-unsupported-browser-features
@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/color-variables';

.SpotonService {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    width: 100%;
    height: 8rem;
    padding: 0.375rem;
    background: $white;
    border: 1px solid $primary30;
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px rgba(53, 63, 94, 0.1);
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;

    &_checkIcon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        margin: 0;
    }

    &_icon {
        min-width: 1.5rem;
        min-height: 1.5rem;
    }

    &_title {
        text-align: center;
        @include p;
    }

    &___active {
        border-color: $primary50;
        border-width: 2px;
    }

    &:hover {
        background-color: $primary10;
        border-color: $primary50;
        border-width: 2px;
    }

    &:focus {
        outline-color: $primary50;
        outline-offset: 0.25rem;
    }
}
