@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.LeadContactPage {
    &_section {
        height: calc(100% - 6rem);
        padding: 4.875rem 0.75rem 0;

        @include screen-smaller-than($desktop) {
            padding-top: 2rem;
        }
    }
}

.Illustration {
    position: relative;
    width: 35rem;
    height: 35rem;
    margin: 0 auto 0;
    padding-top: 2rem;

    @include screen-smaller-than($tablet) {
        width: 100%;
    }
}

.Quotes {
    position: absolute;
    top: 17.25rem;
    left: 0;

    @include screen-smaller-than($tablet) {
        top: 11.25rem;
        left: 2rem;
    }

    &_text {
        max-width: 16rem;
        margin-bottom: 1.125rem !important;
        @include h5;

        @include screen-smaller-than($tablet) {
            max-width: 9rem;
            @include h6;
        }
    }

    &_name {
        @include sub2;
    }

    &_role {
        color: $base90;
        @include p;
    }
}

.Photo {
    position: relative;
    height: 100%;

    @include screen-smaller-than($tablet) {
        width: 100%;
        height: 20rem;
    }

    &_image {
        position: absolute;
        right: 0;
        bottom: 0;
        height: auto;

        @include screen-smaller-than($tablet) {
            top: 15vh;
            right: 0.5rem;
            width: 50vw;
        }
    }
}

.Ellipses {
    position: absolute;
    top: 0;
    left: 2rem;

    @include screen-smaller-than($tablet) {
        left: -2rem;
        width: 100%;
        height: 100%;
    }
}

.Dots {
    position: absolute;
    right: 0;
    bottom: 12.5rem;

    @include screen-smaller-than($tablet) {
        right: 2.5rem;
        bottom: 4.5rem;
        width: 4.5rem;
    }
}

.BuildingWebsite {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: $white;

    @include screen-smaller-than($tablet) {
        top: 85px;
    }

    &_animation {
        width: 50vw;
        height: 50vh;

        @include screen-smaller-than($tablet) {
            width: 80vw;
            height: 30vh;
        }
    }

    &_title {
        margin-bottom: 1rem !important;
        color: $primary70 !important;
        text-align: center;
        @include h2;

        &___mobile {
            @include h5;
        }
    }

    &_subtitle {
        text-align: center;
        @include h5;

        &___mobile {
            // overrides spoton-lib settings
            padding: 0 1rem !important;
            @include p;
        }
    }
}

.BuildingAnimation {
    width: 50%;
    max-width: 50%;

    &_error {
        position: absolute;
        top: 1.5rem;
        right: auto;
        left: 1.5rem;
        z-index: 6;
        justify-content: center;

        @include screen-smaller-than($desktop) {
            right: 0;
        }
    }
}
