/* stylelint-disable plugin/no-unsupported-browser-features, scss/comment-no-loud */
@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$tablet: get-breakpoint('tablet');

.Section {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1;
    flex-direction: column;

    &_headings {
        margin-bottom: 2.5rem;
        text-align: center;
    }

    &_content {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

.Title {
    @include h3;

    @include screen-smaller-than($tablet) {
        margin-bottom: 0.5rem !important;
        @include h5;
    }
}

.Subtitle {
    @include h5;

    @include screen-smaller-than($tablet) {
        @include p;
    }
}
