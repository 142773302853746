@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');

.Layout {
    &_error {
        position: absolute;
        right: 50vw;
        left: 0;
        justify-content: center;

        @include screen-smaller-than($desktop) {
            right: 0;
        }
    }

    &___mobile {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
}

.SplitScreen {
    &___white {
        background-color: $white;
    }
}
