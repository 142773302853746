.Button {
    height: 44px;

    &_icon {
        margin-right: 3px;
    }
}

.AddOptionForm {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;

    &_input {
        min-width: 21rem;
    }
}
