@import 'spoton-lib/styles/color-variables';

// stylelint-disable
.pac-container {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.pac-item {
    border-width: 0;
    
    &:hover {
        background-color: $base10;
    }
}
