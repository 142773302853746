@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

.ConnectedDomain {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &_title {
        font-size: 1.875rem;
        @include h5;
    }

    &_subtitle {
        margin-top: 1.875rem;
        @include h5;
    }
}

.Toast {
    min-height: 10rem;
    margin-top: 2rem;

    &_content {
        position: relative !important; // Needed to fix css specificity issue
        left: -1rem !important; // Needed to fix css specificity issue
    }
}
