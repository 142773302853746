@import 'spoton-lib/styles/color-variables';
@import 'spoton-lib/styles/text';
@import 'spoton-lib/styles/responsive';

$desktop: get-breakpoint('desktop');
$tablet: get-breakpoint('tablet');

.ShareDemoModal {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    border-radius: 0.25rem;

    @include screen-smaller-than($tablet) {
        padding: 1rem;
    }
}

.Close {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row-reverse;

    &_icon {
        width: 2.75rem;
        height: 2.75rem;
        color: $base90;
    }
}

.Content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    text-align: center;

    @include screen-smaller-than($tablet) {
        width: 100%;
    }
}

.Header {
    margin-bottom: 2rem;

    &_title {
        margin-bottom: 1.5rem !important;
        color: $primary90 !important;
        @include h1;

        @include screen-smaller-than($tablet) {
            @include h4;
        }
    }

    &_subtitle {
        color: $base90 !important;
        @include h4;

        @include screen-smaller-than($tablet) {
            @include p;
            @include bold;
        }
    }
}

.Link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    width: 70%;
    margin-bottom: 1.5rem;
    padding: 0.625rem;
    background-color: $base20;
    border-radius: 0.375rem;

    @include screen-smaller-than($tablet) {
        width: 95%;
    }

    &_text {
        color: $primary90 !important;
        word-wrap: break-word;
        @include h6;
    }
}

.Action {
    padding: 0 8rem;

    @include screen-smaller-than($tablet) {
        padding: 0;
    }
}
